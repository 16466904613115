import axios from "axios";
import { getCookie } from "cookies-next";
import useSWR from "swr";

export const api = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });

interface Fetcher {
  url: string;
  params?: any;
}

const fetcher = async <T>({ url, params }: Fetcher) => {
  const token = getCookie("token");

  const res = await api.get<T>(url, {
    params,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

  return res.data;
};

export const fetcherPost = async <T = any>(
  url: string,
  { arg }: { arg: T },
) => {
  const token = getCookie("token");

  const res = await api.post(url, arg, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

  return res.data;
};

export const fetcherPut = async <T = any>(url: string, { arg }: { arg: T }) => {
  const token = getCookie("token");

  const res = await api.put(url, arg, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

  return res.data;
};

export const fetcherPatch = async <T = any, R = any>(
  url: string,
  { arg }: { arg: T },
) => {
  const token = getCookie("token");

  const res = await api.patch<R>(url, arg, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

  return res.data;
};

export async function fetcherDelete(url: string) {
  const token = getCookie("token");

  const res = await api.delete(url, {
    data: {},
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

  return res.data;
}

export const useApi = <T = unknown>(
  url: string,
  params?: any,
  fallback?: T,
) => {
  const { data, mutate, isLoading, error } = useSWR<T>(
    { url, params },
    fetcher,
    {
      revalidateOnFocus: false,
      fallbackData: fallback,
    },
  );

  return { data, mutate, isLoading, error };
};
