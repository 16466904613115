import { BoxProps, Flex, Text, chakra, useToken } from "@chakra-ui/react";
import { SiteEntityType } from "@yotta-vision/core/models/site";
import * as N from "effect/Number";
import { FunctionComponent, useMemo } from "react";
import { BsCircleFill } from "react-icons/bs";

interface Props extends BoxProps {
  sites: SiteEntityType[];
}

const SitesCard: FunctionComponent<Props> = ({ sites }) => {
  const [yottaGreen, yottaRed, yottaOrange, yottaGray] = useToken("colors", [
    "yottaGreenAlert",
    "yottaRedAlert",
    "yottaOrangeAlert",
    "yottaGrayAlert",
  ]);

  const alertCount = useMemo(() => {
    let count = 0;
    sites
      .filter((site) => N.isNumber(site.activeAlertCount))
      .forEach((site) => {
        if (site.activeAlertCount > 0) {
          count++;
        }
      });

    return count;
  }, [sites]);

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      alignSelf="stretch"
      borderRadius="md"
      backgroundColor="statCard.backgroundColor"
      padding="5"
      gap="4"
    >
      <Text
        fontSize="3xl"
        fontWeight="600"
        lineHeight="normal"
        color="statCard.amount"
      >
        {sites.length} <chakra.span fontSize="2xl">sites</chakra.span>
      </Text>
      <Flex direction="row" alignItems="flex-start" gap="5">
        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaGreen} size={14} />
          {sites.length - alertCount}
        </Text>

        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaRed} size={14} />
          {alertCount}
        </Text>

        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaOrange} size={14} />
          {0}
        </Text>

        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaGray} size={14} />
          {0}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SitesCard;
