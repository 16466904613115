import * as Bounded from "@effect/typeclass/Bounded";
import { Bounded as BoundedNumber } from "@effect/typeclass/data/Number";
import convert from "convert";
import { isNullable } from "effect/Predicate";

/**
 * Format a number to a string.
 *
 * Bounds are optional, but if provided, the number will be clamped between the
 * min and max bounds.
 *
 * @section formatting
 */
export const formatNumber = (
  number: number | undefined,
  bounds?: Partial<Bounded.Bounded<number>>,
): string => {
  if (isNullable(number)) {
    return "--";
  }

  const clamp = Bounded.clamp({ ...BoundedNumber, ...bounds });

  return clamp(number).toFixed();
};

/**
 * Format a number to a localized string.
 */
export const formatLocaleNumber = (
  number: number | undefined,
  options?: Intl.NumberFormatOptions,
): string => {
  if (isNullable(number)) {
    return "--";
  }

  return number.toLocaleString("en-US", options);
};

/**
 * Format a percentage value to a string.
 */
export const formatPercentage = (percentage: number | undefined): string => {
  if (isNullable(percentage)) {
    return "--";
  }

  return percentage.toFixed(2) + "%";
};

/**
 * Format an energy value expressed in kWh to a string.
 */
export const formatEnergy = (
  energy: number | undefined,
  bounds?: Partial<Bounded.Bounded<number>>,
) => {
  if (isNullable(energy)) {
    return ["--", "kWh"] as const;
  }

  const clamp = Bounded.clamp({ ...BoundedNumber, ...bounds });

  const value = convert(clamp(energy), "kWh").to("best");

  return [value.quantity.toPrecision(4), value.unit] as const;
};
