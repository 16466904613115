import {
  Box,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { SiteEntityType } from "@yotta-vision/core/models/site";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  site: SiteEntityType;
  children: ReactNode;
}

const HiddenSitePopover: FunctionComponent<Props> = ({ site, children }) => {
  return (
    <Popover placement="top" trigger="hover" offset={[0, 34.5]} isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent border="none" width="100%" zIndex="popover" bg="bg">
          <PopoverArrow bg="bg" />
          <PopoverHeader border="none">
            <Flex flexWrap="nowrap">
              <Box
                bgColor="yottaWhite"
                borderRadius="50%"
                width="14px"
                height="14px"
                marginRight="7px"
                alignSelf="center"
                transition="background-color 300ms"
              />
              <Heading fontSize="lg" color="text">
                {site.name}
              </Heading>
              <Text fontSize="xs" color="subtle-text" marginLeft="15px">
                {site.city}, {site.state} {site.zip}
              </Text>
            </Flex>
          </PopoverHeader>
          <PopoverBody data-testid="popover-body">
            <Text fontSize={12}>Site Hidden</Text>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default HiddenSitePopover;
