import { Box, Heading } from "@chakra-ui/react";
import LoginForm from "components/forms/login-form";
import YottaVisionLogoVertical from "./yotta-vision-logo-vertical";

export default function LoginModalComponent() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bg="subtle-bg"
      padding="30px 10px 30px 10px"
    >
      <Box
        as="section"
        width="45%"
        display="flex"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <YottaVisionLogoVertical role="img" />
      </Box>
      <Box
        as="section"
        display="flex"
        bg="loginModal"
        borderRadius="8px"
        flexDirection="column"
        width="50%"
      >
        <Box height="20%" py="10px" as="header" textAlign="center">
          <Heading color="text">Welcome back!</Heading>
          <Heading color="text" fontWeight="normal" size="xs">
            Please enter your credentials
          </Heading>
        </Box>
        <Box height="65%" padding="50px" display="flex" flexDirection="column">
          <LoginForm />
        </Box>
      </Box>
    </Box>
  );
}
