import { Icon, IconProps, forwardRef } from "@chakra-ui/react";
import type { SiteEntityType } from "@yotta-vision/core/models/site";
import { useRouter } from "next/navigation";
import React from "react";
import { MdLocationPin } from "react-icons/md";
import { isAdmin, useSession } from "~lib/future/contexts/session";
import HiddenSitePopover from "./hidden-site-popover";
import type { Mappable } from "./mappable";
import { SitePopover } from "./site-popover";

export interface MarkerProps extends Mappable {
  site: SiteEntityType;
}

const LocationPin = forwardRef<IconProps, "svg">((props, ref) => (
  <Icon
    as={MdLocationPin}
    position="absolute"
    transformOrigin="bottom center"
    transform="translate(-50%, -100%)"
    _hover={{
      cursor: "pointer",
    }}
    boxSize="5"
    ref={ref}
    {...props}
  />
));

const statusColor = (commissioningStatus: string) => {
  return commissioningStatus === "operational" ? "yottaGreen" : "gray.100";
};

export const Marker: React.FC<MarkerProps> = ({ site }) => {
  const router = useRouter();
  const { session } = useSession();

  const handleClick = React.useCallback(() => {
    router.push(`/sites/${site.siteID}/dashboard`);
  }, [router, site]);

  if (session && session.type === "user") {
    return (
      <>
        {site.isPublic ? (
          <SitePopover site={site}>
            <div onClick={handleClick}>
              <LocationPin color={statusColor(site.commissioningStatus)} />
            </div>
          </SitePopover>
        ) : (
          isAdmin(session) && (
            <HiddenSitePopover site={site}>
              <div onClick={handleClick}>
                <LocationPin color="yottaWhite" />
              </div>
            </HiddenSitePopover>
          )
        )}
      </>
    );
  }

  return <LocationPin color="yottaGreen" />;
};
