import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  RequiredIndicator,
} from "@chakra-ui/react";
import LoginProviders from "components/login-modal/login-providers";
import { useForm } from "react-hook-form";
import { siteInputStyle } from "~lib/theme/shared-styles";

interface LoginFormValues {
  email: string;
  password: string;
}
export default function LoginForm() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm<LoginFormValues>();

  async function onSubmit(data: LoginFormValues) {}
  return (
    <Box
      as="form"
      px="30px"
      gap={5}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl isInvalid={!!errors.email}>
        <FormLabel
          height="unset"
          fontSize="12px"
          htmlFor="email"
          optionalIndicator={<RequiredIndicator />}
        >
          Email
        </FormLabel>
        <Input
          size="sm"
          {...siteInputStyle}
          id="email"
          {...register("email", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message?.toString()}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.password}>
        <FormLabel
          height="unset"
          fontSize="12px"
          htmlFor="password"
          optionalIndicator={<RequiredIndicator />}
        >
          Password
        </FormLabel>
        <Input
          {...siteInputStyle}
          size="sm"
          id="password"
          type="password"
          {...register("password", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>
          {errors.password && errors.password.message?.toString()}
        </FormErrorMessage>
      </FormControl>
      <Box display="flex" alignSelf="center">
        <Checkbox size="sm">Remember this device</Checkbox>
      </Box>
      <Button type="submit" colorScheme="primary">
        Log In
      </Button>
      <LoginProviders />
      <Heading
        mt={10}
        textAlign="center"
        color="text"
        fontSize="12px"
        fontWeight="normal"
      >
        Don&apos;t have an account? <u>Sign up</u>
      </Heading>
    </Box>
  );
}
