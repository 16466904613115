import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import LoginModalComponent from "components/login-modal/login-modal-component";

interface LoginModalProps {
  modalOpen: any;
  modalClose: any;
}

export default function LoginModal({ modalOpen, modalClose }: LoginModalProps) {
  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={modalOpen}
      onClose={modalClose}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent bg="subtle-bg" minWidth="900px">
        <ModalBody>
          <LoginModalComponent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
