import { useColorMode, useToken } from "@chakra-ui/react";

export function useMapStyles() {
  const { colorMode } = useColorMode();

  const [water, road, landcover, terrain, manmade] = useToken("colors", [
    `maps.${colorMode}.water`,
    `maps.${colorMode}.road`,
    `maps.${colorMode}.landcover`,
    `maps.${colorMode}.terrain`,
    `maps.${colorMode}.manmade`,
  ]);

  return [
    {
      featureType: "man_made",
      elementType: "geometry",
      stylers: [{ color: manmade }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: water }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: road }, { weight: 0.5 }],
    },
    {
      featureType: "landscape.natural.landcover",
      stylers: [{ color: landcover }],
    },
    {
      featureType: "landscape.natural.terrain",
      stylers: [{ color: terrain }],
    },
    { elementType: "labels", stylers: [{ visibility: "off" }] },
    {
      featureType: "road.local",
      elementType: "labels.text",
      stylers: [{ visibility: "off" }],
    },
  ] satisfies google.maps.MapTypeStyle[];
}
