import { Box, Flex, Text } from "@chakra-ui/react";
import { OptionProps, Select, components } from "chakra-react-select";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { FunctionComponent } from "react";
import { BsSearch } from "react-icons/bs";

interface Props {
  data: { label: string; siteID: string }[];
  router: AppRouterInstance;
  onSearch?: (value: string) => void;
}
const CustomSearchSelect: FunctionComponent<Props> = ({
  data,
  router,
  onSearch,
}) => {
  const noOptionsMessage = (obj: { inputValue: any }) => {
    return obj.inputValue
      ? `No results found for "${obj.inputValue}"`
      : "No options available";
  };

  const DropdownIndicator = () => {
    return <Box></Box>;
  };

  return (
    <Select
      noOptionsMessage={noOptionsMessage}
      openMenuOnClick={false}
      focusBorderColor="yottaGreen"
      useBasicStyles
      components={{
        DropdownIndicator,
        Option: (props) => <CustomOption {...props} onHover={onSearch} />,
      }}
      options={data}
      onChange={(item) => router.push(`/sites/${item?.siteID}/dashboard`)}
      placeholder={
        <Flex gap="2" alignItems="center">
          <BsSearch />
          <Text color="yottaGreyText">Search or navigate</Text>
        </Flex>
      }
      closeMenuOnSelect={true}
      size="md"
      chakraStyles={{
        inputContainer: (baseStyles) => ({
          ...baseStyles,
          minWidth: "250px",
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: "bg",
        }),
      }}
      styles={{
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? "grey" : "yottaGrey",
        }),
      }}
    />
  );
};

interface CustomOptionProps extends OptionProps<any> {
  onHover?: (value: string) => void;
}

const CustomOption: FunctionComponent<CustomOptionProps> = (props) => {
  const { onHover } = props;

  const handleHover = () => (onHover ? onHover(props.data.label) : undefined);

  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, onMouseEnter: handleHover }}
    >
      {props.children}
    </components.Option>
  );
};

export default CustomSearchSelect;
